import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Two from "../../../images/About/2.jpg";
import "./about.scss";

const AboutOld = () => {
  return (
    <section className="about">
      <article className="about-wrapper">
        <Container>
          <Row className="about-one justify-content-center">
            <Col xs={12} md={8} lg={6} className="img gx-lg-8">
              <img src={Two} alt="" className={"about-image"} />
            </Col>
            <Col
              xs={12}
              lg={6}
              className="content-one d-flex align-items-center gx-lg-8"
            >
              <div>
                <p>
                  CORSnet<span className={"trademark"}>TM</span>, is the first
                  Continuous Observation Reference Station (CORS) Network
                  implemented in Sri Lanka for Real Time Correction (RTK)
                  broadcasting for GNSS Surveying Instruments. CORSnet,
                  originally implemented and deployed by <a className={"bold-link"} href="https://sulecoltd.com/">Suleco (Pvt) Ltd</a> , is
                  now being operated and managed by CORSnet (Pvt) Ltd augmenting
                  its services to various stakeholders.
                </p>
                <p>
                  Global Navigation Satellite System (GNSS) supports generating
                  the geographic location of a user's receiver anywhere in the
                  world. CORSnet fully utilizes such location data obtained from
                  GNSS systems such as GPS, GLONASS, COMPASS & GALLILEO.
                </p>
                <p>
                  Our high accuracy CORS stations located around the island
                  observe these raw satellite data 24x7 hours and transmit the
                  same into Central Control Server for VRS generation, Users and
                  Data Management and Corrections Broadcasting.
                </p>
                <p>
                  With our service, we guarantee accuracy up to 2.5mm+0.5ppm (in
                  Static Mode), accuracy up to 15mm+1ppm (in RTK mode).
                </p>
                <p>
                  RTK Corrections broadcasting consists of data signal streams
                  of RTCM 2.x,/3.x, CMR, CMR+, sCMRx, RTD, NMEA. We also provide
                  RINEX data for post processing.
                </p>
                <p>
                  Affordable pricing, qualified and friendly technical support
                  and reliable prompt service are together in a holistic sense
                  to record high satisfactory performance. We also keep our
                  highest standards of integrity, transparency, and
                  accountability together with our business sustainability and
                  corporate governance policies.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </article>
    </section>
  );
};

export default AboutOld;
