import React from "react";
import teamdata from "./teamdata";
import "./meettheteam.scss";

const MeetTheTeam = () => {
  return (
    <section className="wrapper bg-light">
        <div className="container py-10 py-md-12">
            <div className="row gx-lg-8 gx-xl-12 gy-10 align-items-center">
            <div className="col-lg-5">
                <h2 className="fs-15 text-uppercase text-line text-primary text-center mb-3">Meet the Team</h2>
                <h3 className="display-5 mb-5">Our unique, multi-skilled team is our biggest strength.</h3>
                <p>From the early days of the CORSnet, deployment was fully handled and managed by local experts consisting, Engineers and Surveyors and many field experts graduated from reputed local universities. CORSnet senior management has more than 35 years of combined industry expertise, with contributions to various Spatial Industry initiatives.</p>
            </div>
            <div className="col-lg-6">
                <div className="row">
                    {
                        teamdata.map(({id, img, member, position}) => {
                            return(
                                <div className="col-12 col-md-6 my-2 my-md-0" key={id}>
                                    <div className="position-relative">
                                    <div className="shape rounded bg-soft-blue rellax d-md-block about-z-index" data-rellax-speed="0" style={{bottom: "-0.75rem", right: "-0.75rem", width:"98%", height:"98%", transform: "translate3d(0px, 0px, 0px)"}}></div>
                                    <div className="card">
                                        <figure className="card-img-top"><img className="img-fluid" src={img} srcset={img} alt=""/></figure>
                                        <div className="card-body px-6 py-5">
                                            <h4 className="mb-1">{member}</h4>
                                            <p className="mb-0">{position}</p>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            </div>
          </div>
    </section>
  );
};

export default MeetTheTeam;
