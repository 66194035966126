import React from "react";
import Partners from "../../../images/Register.jpg";

const PartnersComp = () => {
  return (
    <section className='partners'>
      <article className="container mx-auto justify-content-center row py-12 py-md-15">
        <div className="col-12 col-md-5">
          <h2 className="display-4 mb-3">CORSNET PARTNERS</h2>
          <p>We bring solutions to make life easier for our customers.</p>
        </div>
        <div className="col-12 col-md-6">
          <div className="row justify-content-center align-items-center">
            <div className="col-8">
              <img src={Partners} alt="" />
            </div>
          </div>
        </div>
      </article>
    </section>
  );
};

export default PartnersComp;
