import * as React from "react";
import Layout from "../../layout";
import AboutOld from "../../components/About/About/About-old";
import MeetTheTeam from "../../components/About/MeetTheTeam/MeetTheTeam";
import Team from "../../components/About/Team/Team";
import Partners from "../../components/About/Partners/Partners";

const AboutPage = () => {
  return (
    <Layout
      title={"About us"}
      description={
        "The Most Trusted Real Time GNSS Positioning Service in Sri Lanka!"
      }
    >
      <AboutOld />
      <MeetTheTeam />
      <Team />
      {/* <About />
      <TeamGrid/> */}
      <Partners />
    </Layout>
  );
};

export default AboutPage;
