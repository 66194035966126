import React from "react";
import teamdata from "./teamdata";
import "../MeetTheTeam/meettheteam.scss";

const Team = () => {
  return (
    <section className="wrapper bg-light">
      <div className="container py-4 py-md-6">
        <div className="row grid-view gx-md-8 gx-xl-10 gy-8 gy-lg-0">
          {teamdata.map(({ id, img, member, position }) => {
            return (
              <div className="col-md-6 col-lg-3" key={id}>
                <div className="position-relative">
                  <div
                    className="shape rounded bg-soft-blue rellax d-md-block about-z-index"
                    data-rellax-speed="0"
                    style={{
                      bottom: "-0.75rem",
                      right: "-0.75rem",
                      width: "98%",
                      height: "98%",
                      transform: "translate3d(0px, 0px, 0px)",
                    }}
                  />
                  <div className="card">
                    {img ? (
                      <figure className="card-img-top team-portrait">
                        <img className="img-fluid" src={img} alt={member} />
                      </figure>
                    ) : (
                      <div></div>
                    )}
                    <div className="card-body px-6 py-5">
                      <h4 className="mb-1">{member}</h4>
                      <p className="mb-0">{position}</p>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Team;
