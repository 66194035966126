import T1 from "../../../images/About/Rajitha.png";
import T2 from "../../../images/About/Chaminda.png";

const teamdata = [
  {
    id: 1,
    img: T1,
    member: "Rajitha Jayasinghe",
    position: "Director (Eng.)",
  },
  {
    id: 2,
    img: T2,
    member: "Chaminda Bandara Senaviratne",
    position: "Director (Surveyor)",
  },
];

export default teamdata;
